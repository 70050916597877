import styled from 'styled-components'
import Container from '../utils/container'
import BackgroundImage from 'gatsby-background-image'
import { up, down, between } from 'styled-breakpoints'
import Img from 'gatsby-image'


const HeroContainer = styled(Container)`
     ${up('2xl')}{
        max-width: 1300px;
    }
    ${down('xl')}{
        max-width: 1200px;
    }
    ${down('lg')}{
        max-width: 960px;
    }
    
   
`
const HeroWrapper = styled.section`
    background: ${p => p.theme.colors.hero};
    flex-direction: column;
    margin-bottom: -1px;
    li a{
        color: black;
    }
    .hero-logo{
        height: 0;
        padding: 5px 0;
        transform: translateY(-160px);
        ${down('md')}{
            display: none;
        }
    }
    .burger span{
        background: ${p => p.theme.colors.grandAtelier};
      
    }

    ${p => p.hasImage ? `
        .hero-container{
            padding: 10rem 0;
            text-shadow: -1px -1px 19px #000;
        }
    `:''}
    h1, h2 {
        ${down('md')}{
            margin-left: 20px;
            padding-left: 0!important;
        }
        ${p => p.color && `
            color: ${p.color};
        `}
    }
    
    h1{
        font-size: ${p => p.theme.fontSizes['heroTitle']};
        margin-bottom: 15px!important;
        margin-top: 10px!important;
    }
    h2{
        font-size: ${p => p.theme.fontSizes['heroSubtitle']};
    }
    .title h2{
        margin-top: 0;
    }
`
const LogoHeroWrapper = styled(HeroWrapper)`
    background: ${p => p.theme.colors.white};
  
`


const CoverImage = styled(BackgroundImage)`
  &::before,
  &::after{
    filter: brightness(.6);
  }
`

const Logo = styled(Img)`
    margin-left: auto;
    margin-top: 5px;
    margin-bottom: 5px;

    img{
        bottom: 0;
        right: 0;
        margin: auto;
    }
`

export { HeroWrapper, HeroContainer, CoverImage, Logo, LogoHeroWrapper}