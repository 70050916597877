import React from 'react'
import {Markdown} from 'react-showdown'
import SEO from '../components/utils/SEO'
import DefaultLayout from '../components/layouts/defaultLayout'
import {useStaticQuery, graphql} from 'gatsby'
import Footer from '../components/footer/footer'
import Container from '../components/utils/container'
import {AProposWrapper} from '../components/pages/aProposWrapper'
import Hero from '../components/hero/hero'

const Partenaires = () => {
    const {partenaires} = useStaticQuery(graphql`
      query Partenaires {
        partenaires:strapiPartenaires {
          content
        }            
      }
    `)

    return(
    <DefaultLayout>
      <SEO title="Partenaires - Tribal Cabane" description="Nos partenaires, ils nous soutiennent, ils nous font confiance"></SEO> 
      <Hero />
      <AProposWrapper>
          <Container>
              <Markdown className="has-text-left" markup={partenaires.content} />       
          </Container>          
      </AProposWrapper>
      <Footer />
    </DefaultLayout>
    )       
}

export default Partenaires