import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'
import {invert} from 'polished'

const ShortNavWrapper = styled.div`  
    display:flex;
    align-self: center;
    flex-direction: column;
    padding-top: 5px;
    
    h2 {
        font-size: 1.3rem;
        margin-top: 0;
        margin-bottom: 0.2em;
    }
    .flex{
        align-items: center;
        justify-content: flex-end;
        display: flex;
        li{
            list-style:none;
            font-size: 0.95em;
        }
        ul:nth-child(2){
            line-height: 30px;
        }
    }
    a{
        color: ${p => p.color ?? p.theme.colors.white};
        font-weight: bold;
        text-decoration: underline;
        transition: all .25s ease-in;
        padding: 0 10px;
        &:hover{
            background:  ${p => p.color ?? p.theme.colors.white};
            color: ${p => p.color ? invert(p.color) : p.theme.colors.black};
            border-radius: 5px;

        }
    }
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        a { font-size: ${p => p.theme.fontSizes.sm}}
    }
    
`

    const ShortNav = ({color}) => {
    const {bois, cabane, deco, sculpture} = useStaticQuery(graphql`
        query GetSlugs {
            bois: strapiCreations(type: {eq: "Boisflotte"}) {
                slug
                type
            }
            cabane: strapiCreations(type: {eq: "Cabane"}) {
                slug
                type
            }
            deco: strapiCreations(type: {eq: "Decoration"}) {
                slug
                type
            }
            sculpture: strapiCreations(type: {eq: "Sculpture"}) {
                slug
                type
            }
        }
    `);
    
    return(
        <ShortNavWrapper color={color}>
            <div className="flex">
                <ul>
                <h2>Nos autres créations</h2>
                    <li>
                        <Link key={bois.slug} to={`/${bois.type}/${bois.slug}`}>Bois flotté</Link>
                    </li>
                    <li><Link key={cabane.slug} to={`/${cabane.type}/${cabane.slug}`}>{cabane.type}</Link></li>
                    <li><Link key={deco.slug} to={`/${deco.type}/${deco.slug}`}>Décoration</Link></li>
                    <li><Link key={sculpture.slug} to={`/${sculpture.type}/${sculpture.slug}`}>{sculpture.type}</Link></li>
                    <li><Link to={"/village"}>Village des Branchés</Link></li>
                    
                </ul>
                <ul>
                    <h2>Découvrir</h2>
                    <li><Link to={"/ateliers"}>Ateliers</Link></li>
                    <li><Link to={"/partenaires"}>Partenaires</Link></li>
                    <li><Link to={"/evenements"}>Evènements</Link></li>
                    <li><Link to={"/blog"}>Blog</Link></li>
                </ul>
 
            </div>

            
            
        </ShortNavWrapper>
    )
}

export default ShortNav;