import styled from 'styled-components'
import {down} from 'styled-breakpoints'

const AProposWrapper = styled.div`
    padding: 10px 0;
    background-color: ${p => p.theme.colors.contact};
    color: ${p => p.theme.colors.white};
    a{
        color: ${p => p.theme.colors.white};
    }
    ${down('md')}{
        > div {
            padding: 0 25px;
        }
    }   
    p{
        display:inline-block;
    }
`

export {AProposWrapper}