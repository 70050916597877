import React from 'react'
import BackButton from '../utils/backButton'
import MobileNavbar from '../navbar/mobileNavbar'
import {
    HeroWrapper,
    HeroContainer,
} from './heroWrapper'
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import ShortNav from '../navbar/shortNav';
import {down} from 'styled-breakpoints';

const CenteredLogo = styled.div`
    height: 100%;
    display: flex;
    .gatsby-image-wrapper{
        margin: auto;
    }
`

const Row = styled.div`
    display:flex;
    padding: 10px 0;
`

const Col = styled.div`
    width: 33%;
    .subtitle{
        padding-top: 15px;
    }
    ${down('md')}{
        width: 100%;
    }
    ${p => p.hasShortMenu && `
        display: flex;
       
    `}
    
    ${down('lg')}{
        ${p => p.hideOnLargeDown && `
            display: none;
        `}
        ${p => p.hasShortMenu && `
             width: 66%;
             justify-content: flex-end;
        `}
    }
    
    ${down('sm')}{
        ${p => p.hasShortMenu && `
            display:none;
        `}
    }
    
`



const Hero = ({title, subtitle, color}) => {
    const {logoSmall} = useStaticQuery(graphql`
        query HeroData{
            logoSmall : imageSharp(fixed: {originalName: {eq: "logo_x2.png"}}) {
                fixed(width:160) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    `)
    return(
        <HeroWrapper color={color}>
            <MobileNavbar />
            <HeroContainer>
                <BackButton color={color} flat >
                    <div className="hero-title">
                        <h1>{title}</h1>
                        {subtitle && <h2 className="subtitle">{subtitle}</h2> }
                    </div>
                    <CenteredLogo>
                        <Img imgStyle={{filter: 'brightness(0.7)'}} fixed={logoSmall.fixed} />
                    </CenteredLogo>      
                </BackButton>
                      
            </HeroContainer>                
        </HeroWrapper>
    )
  
    

    
}
export default Hero